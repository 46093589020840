/* VARIABLES */

:root {
  --d: 0.5rem;
  --w: 100vw;
  --h: 100vh;
  --c0: #fff;
  --c1: #fbfbfb;
  --c2: #d7d7d7;
  --c3: #b9b9b9;
  --c4: #9e9e9e;
  --c5: #565656;
  --c6: #323232;
  --c7: #414141;
  --c8: #000;
  --s1: 0.6rem;
  --s2: 0.7rem;
  --s3: 0.8rem;
  --s4: 0.9rem;
  --s5: 1rem;
  --s6: 1.1rem;
  --s7: 1.2rem;
  --s8: 1.3rem;
  --s9: 1.4rem;
  --fungi: #e1e1e1;
  --amphibia: #e2deb4;
  --mammalia: #f0c782;
  --plantae: #d8dfd2;
  --insecta: #e2dce2;
  --aves: #d7e3f1;
  --reptilia: #fee2df;
  --body-bg: #f2ebe2;
  --content-bg: #f8f4f0;
  --primary-btn-bg: #fed05e;
  --warning-btn-bg: #ead5df;
  --secondary-action-btn-bg: #e2e9f1;
  --success-bg: #e2e9f1;
  --accent: #4f3c24;
  --accent-light: #9b6727;
  --grid-min-max: 180px;
  --img-min-width: 180px;
  --img-min-height: 180px;
}

/* ELEMENTS */

body {
  font-family: Elena, 'PT Serif', serif;
  background-color: var(--body-bg);
  -webkit-font-smoothing: antialiased;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(var(--w) / 30);
  min-height: 50px;
  border-bottom: 1px dashed var(--c8);
}

nav {
  width: 100%;
  margin: 0;
  padding-inline-start: 0;
  display: flex;
  justify-content: space-evenly;
  font-size: var(--s3);
}

main,
aside {
  margin: auto;
  max-width: 80ch;
}

article:empty {
  display: none;
}

aside * {
  font-size: var(--s2);
}

aside div {
  margin-top: var(--d);
}

h1 {
  margin-bottom: calc(var(--d) * 4);
  max-width: 100ch;
  text-align: center;
  font-size: var(--s6);
  font-weight: 400;
}

h2 {
  display: flex;
  align-items: center;
  margin: calc(var(--d) * 2) 0;
  width: 100%;
  font-size: var(--s5);
  letter-spacing: calc(var(--d) * 0.025);
  -webkit-font-smoothing: antialiased;
}

h3 {
  margin: var(--d) 0;
  font-size: var(--s5);
  letter-spacing: calc(var(--d) / 10);
  color: var(--c6);
}

h4 {
  margin: var(--d) 0;
  font-size: var(--s4);
  letter-spacing: calc(var(--d) / 10);
}

ul {
  margin: 0;
  padding-inline-start: 0;
}

li {
  margin: calc(2 * var(--d)) 0;
  padding: 0;
  list-style-type: none;
}

nav li {
  cursor: pointer;
}

nav ul {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

li.small {
  margin: var(--d) 0;
}

aside nav li {
  margin: calc(1 * var(--d)) 0;
}

li:empty {
  display: none;
}

button {
  margin: 0;
  line-height: calc(var(--d) * 2);
  border: 1px solid var(--accent-light);
  border-radius: calc(var(--d) * 0.5);
  padding: calc(var(--d) * 1.25) calc(var(--d) * 2.5);
  font-size: var(--s3);
  letter-spacing: calc(var(--d) * 0.015);
  background-color: var(--primary-btn-bg);
  cursor: pointer;
}

aside nav li button {
  background-color: var(--content-bg);
}

button.submit:hover {
  border-color: var(--c8);
  background-color: var(--primary-btn-bg);
  opacity: 0.8;
}

button:hover,
button:focus-visible,
button:focus {
  outline: 1px solid var(--accent);
  border: 1px solid transparent !important;
}

.btn-reset {
  position: absolute;
  border: none;
  background-color: initial;
}

label,
.as-label {
  font-size: var(--s3);
  -webkit-font-smoothing: antialiased;
  width: inherit;
  min-width: calc(var(--d) * 7);
  max-width: 500px;
}

label::first-letter {
  text-transform: initial !important;
}

h2 label {
  text-transform: uppercase;
  font-size: var(--s2);
}

h2 label::first-letter,
label.text-initial::first-letter {
  text-transform: initial !important;
}

.flex-row label {
  width: 100%;
}

.grid > div > label {
  margin: 0 0 calc(var(--d) * 0.75);
}

input[type='radio'] + label::first-letter {
  text-transform: uppercase;
}

input[type='checkbox'],
input[type='radio'] {
  margin: 0 calc(var(--d) * 0.5) 0 0;
  accent-color: var(--accent); /* Not supported by Samsung */
  cursor: pointer;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox'] + label,
input[type='radio'] + label {
  margin-bottom: 0;
  letter-spacing: calc(var(--d) * 0.025);
  cursor: pointer;
}

input[type='checkbox'] + label,
.flex-row input[type='radio'] + label {
  position: relative;
}

input[type='checkbox'] {
  flex: none;
}

input[type='text'],
input[type='email'],
input[type='password'],
textarea {
  width: 100%;
  border: 1px solid var(--c3);
  border-radius: calc(var(--d) * 0.5);
}

input[type='date'] {
  border: 1px solid var(--c3);
  border-radius: calc(var(--d) * 0.5);
}

textarea {
  resize: none;
  font-family: inherit;
}

@-moz-document url-prefix() {
  input[type='text'],
  input[type='email'],
  input[type='password'],
  input[type='date'],
  textarea {
    width: 100%;
    width: available; /* WebKit-based browsers will ignore this. */
  }

  input[type='date'] {
    height: 34px;
  }

  input[type='checkbox'] + label {
    text-transform: capitalize;
    display: flex;
    align-items: center;
  }

  h2 input[type='radio'] + label {
    position: relative;
  }

  dl a {
    margin: 0 0 var(--d) 0;
  }

  h2 input[type='radio'] + label,
  .flex-row input[type='radio'] + label {
    top: 1px;
  }
}

input[type='number'] {
  width: calc(var(--d) * 5);
}

input[type='date']::-webkit-datetime-edit {
  padding: var(--d);
}

input[type='date']::-webkit-calendar-picker-indicator {
  margin: var(--d);
  cursor: pointer;
}

select {
  border: 1px solid var(--c3);
  padding: calc(var(--d) * 0.75) 0;
  cursor: pointer;
}

select:focus-visible,
select:focus {
  border: 1px solid transparent;
  outline: 1px solid var(--c8);
}

dl {
  max-width: 70ch;
  margin: 0 auto calc(var(--d) * 4);
  margin-block: 0;
  font-size: var(--s3);
}

dt {
  margin: 0 0 calc(var(--d) * 0.5);
  font-weight: bold;
}

dd,
dl em {
  margin: 0 0 var(--d) 0;
}

dialog div {
  display: flex;
  align-items: center;
  justify-content: center;
}

dd:empty,
div:empty {
  display: none;
}

dt:has(+ dd:empty) {
  display: none;
}

dialog,
dialog:focus-visible,
dialog:focus {
  position: sticky;
  bottom: calc(var(--d) * 2);
  padding: calc(var(--d) * 2);
  border: 1px solid var(--c4);
  border-radius: var(--d);
  outline: none;
  z-index: 2;
}

fieldset {
  position: relative;
  margin: 0 0 calc(var(--d) * 2);
  border: 1px dashed var(--c4);
  padding: calc(var(--d) * 6) calc(var(--d) * 2) calc(var(--d) * 2);
  background-color: var(--content-bg);
}

main > fieldset {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

fieldset.fieldset-reset {
  margin: 0;
  border: none;
  padding: var(--d) 0 0 0;
  background-color: initial;
}

fieldset.fieldset-reset fieldset {
  border-color: var(--c5);
  border-top: none;
}

legend {
  position: absolute;
  top: calc(var(--d) * 0.5);
  left: var(--d);
  padding: calc(var(--d) * 1.5) var(--d);
  font-size: var(--s3);
  font-weight: bold;
  letter-spacing: calc(var(--d) * 0.05);
  color: var(--c7);
}

#lesson-fieldset legend {
  right: var(--d);
}

figure input[type='text'] {
  width: initial;
}

input[type='text']:focus-visible,
input[type='email']:focus-visible,
input[type='password']:focus-visible,
input[type='date']:focus-visible,
textarea:focus-visible {
  border: 1px solid transparent;
  outline: 1px solid var(--c8);
}

a {
  border-bottom: 1px solid var(--c8);
  padding-bottom: calc(var(--d) * 0.25);
  text-decoration: none;
  color: var(--c8);
  line-height: calc(var(--d) * 4);

  &.skip-link,
  &.skip-link:visited {
    position: absolute;
    top: calc(var(--d) * -10);
    display: inline-block;
    color: var(--c1);
    background-color: var(--c5);
  }

  &.skip-link & header {
    position: relative;
  }

  &.info {
    position: relative;
    z-index: 24;
    text-decoration: none;
  }

  &.info span:nth-child(2) {
    position: absolute;
    left: -9000px;
    width: 0;
    overflow: hidden;
  }

  &.selected {
    border-width: calc(var(--d) * 0.15);
  }
}

header nav a {
  border-width: 0;
}

figure {
  margin: 0 auto var(--d) auto;
  text-align: center;
}

figcaption {
  font-size: var(--s3);
  font-style: italic;
}

p {
  margin: calc(var(--d) * 2) 0;
}

th,
td {
  min-width: 100px;
  font-size: var(--s3);
  text-align: left;
  font-weight: normal;
}

img {
  width: 100%;
  margin: var(--d) 0;
  object-fit: contain;
}

/* Write fieldset only */
.species-list figure img {
  aspect-ratio: 1/1;
  width: initial;
  min-height: initial;
  max-height: initial;
  cursor: initial;
  pointer-events: none;
}

/* STATES */

.hidden {
  display: none !important;
}

.invisible {
  display: flex;
  opacity: 0;
  pointer-events: none;
  width: 0;
  min-width: 0;
  height: 0;
}

button:disabled, button.disabled {
  pointer-events: none;
  color: var(--c5);
}

button:not(:disabled) {
  pointer-events: all;
  color: var(--c8);
}

.draggable {
  cursor: pointer;

  & fieldset:active {
    border-color: var(--accent);
  }
}

.drop-before {
  background-color: var(--primary-btn-bg);
}

.moveable {
  cursor: grabbing;
}

.initial-width {
  min-width: initial;
}

.initial-text {
  text-transform: initial !important;
}

.error-bg {
  background-color: var(--warning-btn-bg);
}

.success-bg {
  background-color: var(--success-bg);
}

.success-icon::before {
  content: '\2713';
  margin-right: calc(var(--d) * 0.5);
}

.error-icon::before {
  content: '\2717';
  margin-right: calc(var(--d) * 0.25);
}

.capitalise {
  text-transform: capitalize;
}

.serif {
  font-family: Elena, 'PT Serif', serif;
}

.alert {
  border-color: var(--warning-btn-bg);
}

.btn-alert {
  border-color: var(--accent);
  background-color: var(--warning-btn-bg);
}

.btn-secondary-action {
  background-color: var(--secondary-action-btn-bg);
  border: 1px solid var(--accent);
}

.btn-delete-action {
  background-color: var(--warning-btn-bg);
  border: 1px solid var(--accent);
}

.border-solid {
  border: 1px solid var(--c8);
}

.max-content {
  width: max-content;
}

.object-fit-none {
  object-fit: none;
}

/* SELECTOR RULES */

.non-grid {
  margin: 0 auto calc(var(--d) * 4);
  max-width: 30ch;

  & :has(h3),
  .non-grid:has(h4) {
    margin-bottom: 0;
  }

  & p {
    line-height: calc(var(--d) * 4);
    font-size: var(--s6);
    color: var(--c6);
    -webkit-font-smoothing: antialiased;
  }
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  gap: var(--d);
  margin: 0 auto calc(var(--d));

  & figure img {
    border-radius: calc(var(--d) * 0.5) calc(var(--d) * 0.5) 0 0;
    cursor: initial;
    pointer-events: none;
    width: 200px;
  }

  &:has(figure, img) {
    margin: 0 auto;
  }

  & > * {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  &:not(:has(figure)) img {
    object-fit: cover;
    cursor: pointer;
  }

  & .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: calc(var(--d) * 1.5) var(--d);
    border: 1px dotted var(--c2);
    border-radius: calc(var(--d) * 0.5);
    background-color: var(--body-bg);
  }

  & div > div,
  & figure > figcaption {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(6 * var(--d));
    padding: var(--d);
    font-size: var(--s3);
  }

  & span::first-letter {
    text-transform: capitalize;
  }

  & figcaption span:nth-child(2) {
    margin-top: calc(var(--d) * 0.5);
    font-size: var(--s3);
  }

  a.info:hover span:nth-child(2),
  a.info:focus span:nth-child(2),
  a.info:active span:nth-child(2) {
    display: block;
    position: absolute;
    top: calc(var(--d) * -4.5);
    left: calc(var(--d) * 5);
    border: 1px solid var(--c2);
    width: calc(var(--d) * 20);
    padding: var(--d);
    text-align: center;
  }

  & .species-panel {
    display: flex;
    font-size: var(--s3);
  }

  & .species-panel:empty {
    display: none;
  }

  & .species-panel hgroup p {
    white-space: pre-line;
  }
}

.inat-preferences-section .grid {
  grid-template-columns: repeat(auto-fit, minmax(100px, auto));
}

#lesson legend:empty {
  display: none;
}

#inat-autocomplete-input-text::placeholder {
  opacity: 1;
}

#selected-term {
  font-size: var(--s3);
}

#terms label,
#selected-term span:nth-child(1) {
  min-width: calc(var(--d) * 10);
}

#selected-terms-list li {
  display: flex;
  margin: var(--d) 0;
}

/* ALL-PURPOSE RULES */

.block {
  display: inline-block;
}

.centred-block {
  display: flex;
  align-items: center;
}

.justified-space-between-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.flex-columns {
  display: flex;
  flex-direction: column;
}

.flex-columns > * {
  flex: 1;
}

.double-centred-block {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  border: 1px solid var(--c2);
  padding: var(--d);
}

.section-header {
  display: flex;
  justify-content: space-between;
}

.section-group {
  margin-bottom: calc(var(--d) * 0.25);
  padding-left: calc(var(--d) * 2);
  align-items: center;
  color: var(--c6);
  background-color: var(--c0);
}

.section-group input:checked {
  border-color: var(--c1);
}

.section-group:has(input:checked) {
  color: var(--c1);
  background-color: var(--c5);
}

section:empty {
  display: none;
}

.latin {
  font-style: italic;
}

.as-p {
  position: initial;
  padding: 0 0 var(--d) 0;
  width: initial;
  font-weight: initial;
}

.double-height {
  height: calc(var(--d) * 4);
}

.fl {
  float: left;
}

.btn-secondary {
  padding: var(--d);
  width: initial;
  border: 1px solid transparent;
  font-size: var(--s1);
  font-weight: initial;
  text-transform: uppercase;
  color: var(--c6);
  background-color: var(--content-bg);
}

.btn-absolute {
  position: absolute;
  top: var(--d);
  right: calc(var(--d) * 2);
  left: initial;
}

.btn-secondary-bottom {
  top: initial;
  bottom: var(--d);
}

.btn-secondary:focus {
  background-color: var(--c0);
  outline: 1px solid var(--c4);
}

.btn-save {
  background-color: var(--secondary-action-btn-bg);
}

/* CONTROLS */

.show-hide {
  border: none;
  font-size: var(--s2);
  text-transform: uppercase;
  color: var(--c5);
  background-color: var(--c1);
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  max-height: 40.5px;
}

#observation-dates input[type='date'],
#observation-dates label {
  font-size: var(--s1);
}

#observation-dates label:nth-child(3),
#observation-dates label:nth-child(2) {
  min-width: calc(var(--d) * 4);
}

#observation-dates input[type='date'] {
  width: calc(var(--d) * 13);
}

#observation-dates > div > label:nth-child(2) {
  width: calc(var(--d) * 4);
}

#observation-dates div:nth-child(4) > label:nth-child(2) {
  width: initial;
}

#select-section-type-section {
  position: sticky;
  top: var(--d);
  z-index: 1;
  padding-bottom: var(--d);
}

#select-section-type-section fieldset {
  padding-block-start: calc(var(--d) * 4);
  border-color: var(--c5);
  border-style: solid;
}

fieldset.species-list {
  padding: calc(var(--d) * 6) calc(var(--d) * 2);
}

/* VIEWS */

.edit-view *:not(input, textarea) {
  width: initial;
  max-width: initial;
}

/* POSITIONING */

.text-left {
  text-align: left;
}

/* SPACING */

.m {
  margin: var(--d);
}

.m0 {
  margin: 0;
}

.mt {
  margin-top: var(--d);
}

.mt0 {
  margin-top: 0;
}

.mt2 {
  margin-top: calc(var(--d) * 2);
}

.mr {
  margin-right: var(--d);
}

.mr2 {
  margin-right: calc(var(--d) * 2);
}

.mb0 {
  margin-bottom: 0;
}

.mb05 {
  margin-bottom: calc(var(--d) * 0.5);
}

.mb {
  margin-bottom: var(--d);
}

.mb2 {
  margin-bottom: calc(var(--d) * 2);
}

.mb4 {
  margin-bottom: calc(var(--d) * 4);
}

.ml {
  margin-left: var(--d);
}

.ml2 {
  margin-left: calc(var(--d) * 2);
}

.ml4 {
  margin-left: calc(var(--d) * 4);
}

.ml6 {
  margin-left: calc(var(--d) * 6);
}

.ml7 {
  margin-left: calc(var(--d) * 7);
}

.ml8 {
  margin-left: calc(var(--d) * 8);
}

.p {
  padding: var(--d);
}

.pt0 {
  padding-top: 0;
}

.pt {
  padding-top: var(--d);
}

.pt2 {
  padding-top: calc(var(--d) * 2);
}

.pt6 {
  padding-top: calc(var(--d) * 6);
}

.pr {
  padding-right: var(--d);
}

.pr2 {
  padding-right: calc(var(--d) * 2);
}

.p0 {
  padding: 0;
}

.pb0 {
  padding-bottom: 0;
}

/* SIZE */

.small {
  font-size: var(--s3);
  line-height: calc(var(--d) * 2.5);
}

.smallish {
  font-size: var(--s2);
  line-height: calc(var(--d) * 2);
}

.tiny {
  font-size: var(--s1);
}

.t0 {
  top: 0;
}

.t1 {
  top: var(--d);
}

.r1 {
  right: var(--d);
}

.mw4 {
  min-width: calc(var(--d) * 4);
}

.mw8 {
  min-width: calc(var(--d) * 8);
}

.caption {
  display: block;
  width: 100%;
  padding: calc(var(--d) * 2) 0;
  text-align: center;
  font-family: sans-serif;
}

/* MEDIA */

@media (orientation: landscape) {
  header {
    margin: 0 10vw;
  }

  #article {
    margin-top: calc(var(--d) * 4);
  }

  #header span {
    top: calc(var(--d) * 1);
  }

  aside form {
    width: 60%;
    margin: auto;
  }

  dl {
    margin: 0 auto calc(var(--d) * 4);
    border: 1px solid var(--c3);
    border-radius: calc(var(--d) * 0.5);
    padding: calc(var(--d) * 2) calc(var(--d) * 2) 0;
  }
}

@media only screen and (width >= 32em) {
  #observation-dates input[type='date'] {
    width: initial;
    font-size: var(--s3);
  }

  #observation-dates label {
    font-size: var(--s3);
  }

  #observation-dates label:nth-child(3),
  #observation-dates label:nth-child(2) {
    min-width: calc(var(--d) * 6);
  }
}

@media screen and (width >= 458px) {
  .non-grid {
    max-width: 60ch;
  }

  & figure img {
    aspect-ratio: 1/1;
    min-height: var(--img-min-height);
    max-height: var(--img-min-height);
    margin: 0;
    object-fit: cover;
  }
}

@media screen and (width <= 458px) {
  figcaption {
    background-color: var(--content-bg) !important;
  }

  .grid figure img {
    width: 240px;
  }

  .non-grid {
    margin-left: 0;
    margin-right: 0;
    max-width: none;
  }
}

/* Widgets */
#carbon-neutral-website,
#the-green-web-foundation {
  & a {
    border: none;
  }

  & img {
    object-fit: none;
  }
}
